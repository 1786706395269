import {
  FETCH_USER_METADATA_REQUEST,
  FETCH_USER_METADATA_SUCCESS,
  FETCH_USER_METADATA_FAILURE
} from './actionTypes';

const initialState = {
  data: {},
  fetch: false
};

export const userMetadata = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_METADATA_REQUEST:
      return {
        ...state,
        fetch: false
      }

    case FETCH_USER_METADATA_SUCCESS:
      return {
        data: action.data,
        fetch: true
      }

    case FETCH_USER_METADATA_FAILURE:
      return {
        ...state,
        fetch: false
      }

    default:
      return state;
  }
}
