import React from 'react';
import LogoutButton from './LogoutButton';

export default function Header(props) {
  return (
    <div className="main-header">
      <div className="logo-header" data-background-color="blue">
        <div className="logo d-flex">
          <div Style="color: #fff"> <i className="fas fa-file-code"></i> SC-Report</div>
        </div>
      </div>
      <nav className="navbar navbar-header navbar-expand-lg" data-background-color="blue2">
        <div id="alert-div"></div>
        <div className="container-fluid">
          <div className="d-flexX d-none">
            <div class="spinner"></div> <div Style="color:white;margin:2px 10px">Generando reporte...</div>
          </div>
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item toggle-nav-search hidden-caret">
              <a className="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-expanded="false" aria-controls="search-nav">
                <i className="fa fa-search"></i>
              </a>
            </li>


            <li className="nav-item dropdown hidden-caret">
              <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
                <div className="avatar">
                  <img className="avatar-title rounded-circle border border-white bg-info" src={props.userPicture} alt="Usuario" />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-user animated fadeIn">
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    {/* <a className="dropdown-item" href="#" data-toggle="modal" data-target="#exampleModal">Cambiar contraseña</a>
                                            <div className="dropdown-divider"></div> */}
                    <a className="dropdown-item" href="#"><LogoutButton /></a>

                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>

    </div>
  )
}