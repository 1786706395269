import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from '../../ui/variables';
import LogoutButton from './LogoutButton';
import { appAuth0 } from '../../config/environments';
import { categoryService } from '../../pages/state/category/service';

const Sidebar = ({ userRole, userName, userPicture }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [list, setList] = useState([]);
  const getToken = async () => {

    const accessToken = await getAccessTokenSilently({
      audience: appAuth0.audience,
      scope: 'read:current_user'
    });
    return accessToken;
  };

  const getListByRol = (categories, roleName) => {
    const reportIdByRole = {
      [ROLES.MARKETPLACE_PLAZAVEA]: 131,
      [ROLES.MARKETPLACE_PROMART]: 130,
    };

    const reportId = reportIdByRole[roleName];

    if (reportId) {
      return categories
        .map(category => {
          // Verificar que category.reports exista y filtrar los reports por el reportId
          const filteredReports = (category.reports || []).filter(report => report.id === reportId);

          // Si se encuentra el reporte, devolver solo la categoría con ese reporte
          if (filteredReports.length > 0) {
            return {
              id: category.id,
              name: category.name,
              reports: filteredReports // Eliminar el array extra alrededor de filteredReports
            };
          }
          return null;
        })
        .filter(item => item !== null) // Remover los elementos nulos
        .slice(0, 1); // Devolver solo el primer resultado encontrado
    }

    // Si el rol no coincide o no hay reportId, devolver las categorías sin cambios
    return categories;
  };

  const getData = async (roleName) => {
    const accessToken = await getToken();
    const response = await categoryService.getAllReports(accessToken);
    const listFinal = await getListByRol(response.data, roleName);
    setList(listFinal);
  };

  useEffect(() => {
    userRole && getData(userRole);
  }, [userRole]);

  return (
    <div className="sidebar sidebar-style-2">
      <button class="d-none" id="sidebar" onClick={() => getData(userRole)}></button>
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <div className="user pl-0">
            <div className="avatar-sm float-left mr-3">
              <div className="avatar">
                <img className="avatar-title rounded-circle border border-white bg-info" src={userPicture} alt="Usuario" />
              </div>
            </div>
            <div className="info">
              <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                <span>
                  {userName}
                  <span className="user-level">{userRole}</span>
                  <span className="caret"></span>
                </span>
              </a>
              <div className="clearfix"></div>

              <div className="collapse in" id="collapseExample">
                <ul className="nav">
                  <li>
                    <LogoutButton />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul className="nav nav-primary">
            <li className="nav-item">
              <NavLink to={"/"} activeClassName="active">
                <i className="fas fa-home"></i>
                <p>Inicio</p>
              </NavLink>
            </li>
            <li className="nav-section">
              <span className="sidebar-mini-icon">
                <i className="fa fa-ellipsis-h"></i>
              </span>
              <h4 className="text-section">Reportes</h4>
            </li>
            {list.map(({ id, name, reports }) => {
              name = (name.length > 15) ? name.substr(0, 15) + '...' : name
              return (
                <li key={id} className="nav-item">
                  <a data-toggle="collapse" href={'#base-' + id} className="collapsed" aria-expanded="false">
                    <i className="fas fa-layer-group"></i>
                    <p>{name}</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id={'base-' + id}>
                    <ul className="nav nav-collapse">
                      {reports.map(({ name, id }) => {
                        name = (name.length > 35) ? name.substr(0, 35) + '...' : name
                        return (
                          <li key={id}>
                            <NavLink
                              key={id}
                              to={'/ver-reporte/detail?=' + id}
                              activeClassName="active"
                              isActive={(match, location) => {
                                return (location.pathname + location.search === '/ver-reporte/detail?=' + id)
                              }}
                            >
                              <span className="sub-item">{name}</span>
                            </NavLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </li>)
            })}
            {userRole === ROLES.ADMIN_REPORTEADOR && (
              <>
                <li className="nav-section">
                  <span className="sidebar-mini-icon">
                    <i className="fa fa-ellipsis-h"></i>
                  </span>
                  <h4 className="text-section">Administrar</h4>
                </li>

                <li className="nav-item">
                  <NavLink to={"/reportes"} activeClassName="active">
                    <i className="fas fa-file-alt"></i>
                    <p>Reportes</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/categorias"} activeClassName="active">
                    <i className="fas fa-tasks"></i>
                    <p>Categorías</p>
                  </NavLink >
                </li>
                <li className="nav-item">
                  <NavLink to={"/conexiones"} activeClassName="active">
                    <i className="fas fa-server"></i>
                    <p>Conexiones</p>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;