import React, { useState } from 'react'
import Sidebar from '../components/generic/Sidebar'
import Footer from '../components/generic/Footer'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2';
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism.css";
import { appAuth0 } from '../config/environments';
import { reportService } from './state/report/service';
import { connectionService } from './state/connection/service';
import { categoryService } from './state/category/service';
import { withAuth0 } from '@auth0/auth0-react';

const getConnection = async (t) => {
  const accessToken = await t.getToken();
  const response = await connectionService.getAll(accessToken);
  
  t.setState({cnx:response.data});
}

const getCategory = async (t) => {
  const accessToken = await t.getToken();
  const response = await categoryService.getAll(accessToken);
  
  t.setState({cat:response.data});
}

const getReportData = async (t, id) => {
  const accessToken = await t.getToken();
  const response = await reportService.get(accessToken, id);

  return response;
}

const saveReport = async (t) =>{
  const accessToken = await t.getToken();
      debugger;
      console.log(t.state);
  if(t.state.id==0) {
    await reportService.post(accessToken, t.state);

    document.getElementById('sidebar').click();
    Swal.fire({
      title: "¡Guardado!",
      text: "Se guardó el reporte",
      icon: "success",
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2000
    }).then((result) => {
      document.getElementById('return-reports').click();
    })
  }
  else {
    await reportService.put(accessToken, t.state);

    document.getElementById('sidebar').click();
    Swal.fire({
      title: "¡Guardado!",
      text: "Se actualizó el reporte",
      icon: "success",
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2000
    }).then((result) => {
      document.getElementById('return-reports').click();
    })
  }
}

const hightlightWithLineNumbers = (input, language) =>
        highlight(input, language)
            .split("\n")
            .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join("\n")

const EditorSql = (props) => { 
    let [codeValue, setCodeValue] = useState(props.thix.state.query);
    
    return (
      <Editor
        value={props.thix.state.query}
        onValueChange={ code => {setCodeValue(code);props.thix.setState({query:code});showParams(props.thix)}}
        highlight={code => hightlightWithLineNumbers(code, languages.sql)}
        padding={10}
        textareaId="codeArea"
        className="editor"
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 14,
          outline: 0,
          borderRadius:10,
          background:"#f7f9fb"
        }}
      />
    )
}

const showParams = (t) =>{
    let txt = t.state.query
    txt = txt.replaceAll('=@','= @').replaceAll('<@','< @').replaceAll('>@','> @').replaceAll('(@','( @')
    let a = txt.split(/(^|\s)(@[A-Za-z0-9-\d_]+)/)
  
    let rsp = t.state.params; 
    for(let i=0;i<a.length;i++){
        if(a[i][0]!='@') continue;

        let bnd=true;
        for(let j=0;j<rsp.length;j++)
            if(rsp[j].attr==a[i]) bnd=false;

        if (bnd) rsp.push({attr:a[i],name:"",type:"text"})  
    }
    
    for(let j=0;j<rsp.length;j++){
        let bnd=true
        for(let i=0;i<a.length;i++){
            if(a[i][0]!='@') continue;
            if(a[i]==rsp[j].attr) bnd=false
        }
        if(bnd) rsp.splice(j,1)
    }
}

const paramsSet = (t,attr,value,n) => {
    let params = []
    let p = t.state.params;
    for(let i=0;i<p.length;i++){
        if(p[i].attr==attr) (n)? p[i].name=value : p[i].type=value;
        params.push(p[i])
    }
    t.setState({params})
}

class CreateReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cnx:[],
            cat:[],
            id:"",
            name:"",
            idConnection:0,
            idCategory:0,
            query:"",
            params:[]
        }
        this.getSelects()
        this.getReport(this.props.match.params.id)
    }

    async getToken() {
      const { getAccessTokenSilently } = this.props.auth0;
    
      const accessToken = await getAccessTokenSilently({
        audience: appAuth0.audience,
        scope: 'read:current_user'
      });
      return accessToken;
    };
  
    async getSelects() {
      await getConnection(this);
      await getCategory(this);
    }

    async getReport(id){
      const response = await getReportData(this, id);
      let obj = response.data;

      this.setState({id});
      this.setState({name:obj.name});
      this.setState({idConnection:obj.idConnection});
      this.setState({idCategory:obj.idCategory});
      this.setState({query:obj.query});
      this.setState({params:obj.params});
    }
    
    render() {
      const { userRole, userName, userPicture } = this.props;

      return (
        <>
          <Sidebar userRole={userRole} userName={userName} userPicture={userPicture} />
          <div className="main-panel">
            <div className="container">
              <div className="panel-header bg-primary-gradient">
              <div className="page-inner py-5">
                  <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                      <div>
                          
                          <h2 className="text-white pb-2 fw-bold">Crear Reporte</h2>
                          <h5 className="text-white op-7 mb-2">Aqui puede crear su reportes</h5>
                      </div>
                      
                      <div className="ml-md-auto py-2 py-md-0">
                          <Link id="return-reports" to="/reportes" className="btn btn-white btn-border btn-round mr-2">Volver</Link>
                          <button 
                              className="btn btn-info btn-round"
                              onClick={()=>saveReport(this) }
                          >
                          Guardar cambios
                          </button>
                      </div>
                      
                  </div>
              </div>
          </div>
              <div className="page-inner mt--5">
              <div className="row">
                              
                  <div className="col-md-12">
                      <div className="card">
                          <div className="card-header">
                              <div className="card-head-row">
                                  <div className="card-title">Configuración</div>
                              </div>
                          </div>
                          <div className="card-body row">
                                  <div className="col-12 mb-4">
                                      <div className="form-group form-floating-label">
                                                      <input id="name-report" Style="font-size:26px" type="text" className="form-control input-border-bottom" autoComplete="off" value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})} />
                                                      <label htmlFor="name-report" className="placeholder">Nombre del reporte</label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="form-group form-floating-label">
                                          <select className="form-control input-border-bottom" Style="font-size:20px" id="categories" value={this.state.idCategory} onChange={(e)=>this.setState({idCategory:e.target.value})} >
                                              <option value="0"></option>
                                              {this.state.cat.map(({id,name})=>{
                                                  return ( <option value={id}>{name}</option>)
                                              })}
                                          </select>
                                          <label htmlFor="categories" className="placeholder">Categoría</label>
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="form-group form-floating-label">
                                          <select className="form-control input-border-bottom" Style="font-size:20px" id="connections" value={this.state.idConnection} onChange={(e)=>this.setState({idConnection:e.target.value})} >
                                              <option value="0"></option>
                                              {this.state.cnx.map(({id,name,type})=>{
                                                  return ( <option value={id}>{type+' - '+name}</option>)
                                              })}
                                          </select>
                                          <label htmlFor="connections" className="placeholder">Conexión</label>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>


                  <div className="col-md-12">
                      <div className="card">
                          <div className="card-header">
                              <div className="card-head-row">
                                  <div className="card-title">Consulta SQL</div>
                              </div>
                          </div>
                          <div className="card-body">
                                  
                                  <div>
                                      <div className="mb-1">Código SQL:</div> 
                                      <EditorSql thix={this}/>
                                  </div>
                                  
                                  { this.state.params.length>0 &&
                                      <div className="mt-3 mb-1">Parámetros:</div>    
                                  }
                                  <div className="row">
                                      
                                      {this.state.params.map(({attr,name,type})=>{ return(
                                          <div className="col-md-3">
                                              <div className="d-flex" Style="background:#f7f9fb;border-radius:10px;border:1px solid #ebecec">
                                                  <div className="form-group" Style="width:60%">
                                                      <label Style="color:#e90!important">{attr}</label>
                                                      <input type="text" className="form-control" placeholder="Nombre a mostrar" autoComplete="off" value={name} onChange={(e)=>paramsSet(this,attr,e.target.value,true)}/>
                                                  </div>
                                                  <div className="form-group">
                                                      <label htmlFor={'sc'+attr}>Tipo</label>
                                                      <select className="form-control" id={'sc'+attr} value={type} onChange={(e)=>paramsSet(this,attr,e.target.value,false)}>
                                                          <option value="text">Texto</option>
                                                          <option value="integer">Entero</option>
                                                          <option value="decimal">Decimal</option>
                                                          <option value="date">Fecha</option>
                                                          <option value="list">Lista</option>
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                      )})}
                                  </div>
                                          
                          </div>
                      </div>
                  </div>


              

              </div>
          </div>
            </div>
            <Footer/>
          </div>
        </>
      )
    }
}

export default withAuth0(CreateReport)