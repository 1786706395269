import React from 'react';
import { images } from '../../assets/images';
import { LoaderContainer, LoaderImage, LoaderMessage } from './styled';

export const Loader = (props) => {
  const srcImage = images.loader || props.image;

  return (
    <LoaderContainer>
      <LoaderImage src={srcImage} alt="loader" data-size={props.size} />
      { props.message && <LoaderMessage>{props.message}</LoaderMessage>}
    </LoaderContainer>
  );
};
