import styled from 'styled-components';
import { COLORS, FONTS } from '../../ui/variables';

export const LoaderContainer = styled.div`
  text-align: center;
`;

export const LoaderImage = styled.img`
  height: ${(props) => props['data-size'] || '50px'};
  width: ${(props) => props['data-size'] || '50px'};
`;

export const LoaderMessage = styled.p`
  color: ${COLORS.GRAY};
  font-family: ${FONTS.ROBOTO};
  font-size: 14px;
  line-height: 16px;
  margin: 20px;
`;
