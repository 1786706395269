import React from 'react'
import Sidebar from '../components/generic/Sidebar'
import Footer from '../components/generic/Footer'
import { withAuth0 } from '@auth0/auth0-react';

class Dashboard extends React.Component {
  render(){
    const { userRole, userName, userPicture } = this.props;

    return (
      <>
        <Sidebar userRole={userRole} userName={userName} userPicture={userPicture} />
        <div className="main-panel">
          <div className="container">
            <div></div>
          </div>
          <Footer/>
        </div>
      </>
    )
  }
}
export default withAuth0(Dashboard)