import React from 'react'

export default function Footer() {
    return (
            <footer className="footer">
                <div className="container-fluid">
                    <nav className="pull-left">
                        <ul className="nav"></ul>
                    </nav>
                    <div className="copyright ml-auto">
                         2021 SC-Report, una app de <a href="https://google.com" target="_blank">TuEmpresa.com</a> | Version 1.2
                    </div>				
                </div>
            </footer>
    )
}