import {
  CONNECTIONS_GET,
  CONNECTIONS_GET_ALL,
  CONNECTIONS_GET_ALL_REPORTS,
  CONNECTIONS_POST,
  CONNECTIONS_PUT,
  CONNECTIONS_DEL
} from '../../../utils/urls';
import { httpConfig } from '../../../config/http';

export const connectionService = {
  async get(accessToken, id) {
    const response = await httpConfig(accessToken)
      .get(CONNECTIONS_GET + id);
  
    return response;
  },
  async getAllReports(accessToken, id) {
    const response = await httpConfig(accessToken)
      .get(CONNECTIONS_GET_ALL_REPORTS);
  
    return response;
  },
  async getAll(accessToken) {
    const response = await httpConfig(accessToken)
      .get(CONNECTIONS_GET_ALL);
  
    return response;
  },
  async post(accessToken, data) {
    const response = await httpConfig(accessToken)
      .post(CONNECTIONS_POST, data);

    return response;
  },
  async put(accessToken, data) {
    const response = await httpConfig(accessToken)
      .put(CONNECTIONS_PUT + data.id, data)

    return response;
  },
  async delete(accessToken, id) {
    const response = await httpConfig(accessToken)
      .delete(CONNECTIONS_DEL + id)

    return response;
  }
}
