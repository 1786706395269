import { appAuth0 } from '../../../config/environments';

const URL = appAuth0.audience;

export const userMetadataService = {
  async get(id, token) {
    const URL_LOCAL = `${URL}users/${id}`;
    const response = await fetch(URL_LOCAL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    return response.json();
  }
}
