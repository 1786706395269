import inretailLogo from './intercorp.png';
import loader from './loader.gif';

interface Images {
  inretailLogo: string;
  loader: string;
}

export const images: Images = {
  inretailLogo,
  loader
};
