import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import { AlertList, Alert, AlertContainer } from "react-bs-notifier"
import Sidebar from '../components/generic/Sidebar'
import Footer from '../components/generic/Footer'
import {
    URL_BASE_PATH
} from '../utils/urls'
import { appAuth0 } from '../config/environments';
import { reportService } from './state/report/service';
import { withAuth0 } from '@auth0/auth0-react';

const getId = (props) => {
  return props.location.search.split('?=')[1];
}
  
const load = async (t, id) => {
  const accessToken = await t.getToken();
  const response = await reportService.load(accessToken, id);

  t.setState({load:response.data})
}

const executeService = async (t, data) => {
  const accessToken = await t.getToken();
  const response = await reportService.execute(accessToken, data);

  t.setState({datax:response.data});
  t.setState({execute:false});
}

const exportService = async (t, data, isCsv) => {
  const accessToken = await t.getToken();
  const response = await reportService.export(accessToken, data);

  (isCsv) ? t.setState({executeCsv:false}) : t.setState({executeExcel:false});

  if(response.data[0]=='OK')
    alertShow('success', (response.data[2]=='back')? URL_BASE_PATH+'/'+ response.data[1] : response.data[1]);
  else
    alertShow('danger', response.data[1]);
}

const paramsSet = (t,attr,value) => {
    let id = getId(t.props);
    let load = {
        id,
        name: t.state.load.name,
        params:t.state.load.params,
        total:t.state.load.total,
        page:t.state.load.page,
        show:t.state.load.show
    }
    let p = t.state.load.params
    let params = []
    for(let i=0;i<p.length;i++){
        if(p[i].attr==attr) p[i].value=value;
        params.push(p[i])
    }
    load.params=params
    t.setState({load})
}


const alertHide = () => {
    ReactDOM.render(<div/>, document.getElementById('alert-div'));
}

const alertShow = (type, text) => {
    if(type=='success'){
        ReactDOM.render(<Alert type={type} headline="Se generó el reporte correctamente" onDismiss={alertHide}>Si no se descargó el reporte haga <a href={text} id="report-click" className="alert-link" target="_blank">Click aquí.</a></Alert>, document.getElementById('alert-div'));
        document.getElementById('report-click').click();
    }else
        ReactDOM.render(<Alert type={type} headline="ERROR AL GENERAR EL REPORTE" onDismiss={alertHide}>{text}</Alert>, document.getElementById('alert-div'));    
}


class ReportViewer extends React.Component {
    constructor(props) {
        super(props)
        let id = getId(props);
        this.state = {
            execute:false,
            executeCsv:false,
            executeExcel:false,
            load:{
                id,
                name:"",
                total:0,
                page:1,
                show:5,
                params:[]
            },
            datax:{
                error:false,
                message:"",
                total:0,
                header:[],
                body:[],
            }
        }
        this.getList(props)
    }

    componentWillReceiveProps = (p) => {
        let id = getId(p);
        let state = {
            execute:false,
            bndInit:false,
            load:{
                id,
                name:"",
                total:0,
                page:1,
                show:5,
                params:[]
            },
            datax:{
                error:false,
                message:"",
                total:0,
                header:[],
                body:[],
            }
        }    
        this.setState({load:state.load})
        this.setState({datax:state.datax})
        this.getList(p)
    }

    async getToken() {
      const { getAccessTokenSilently } = this.props.auth0;
    
      const accessToken = await getAccessTokenSilently({
        audience: appAuth0.audience,
        scope: 'read:current_user'
      });
      return accessToken;
    };

    getList = async (p) => {
        let thix = this;
        let id = getId(p);
        await load(thix, id);
    }

    executeReport = () => { 
        if(this.state.execute) return; 
        this.setState({bndInit:true})
        
        let bnd = false;
        for(let i=0;i<this.state.load.params.length;i++){
          if(this.state.load.params[i].value==''||this.state.load.params[i].value==undefined) bnd=true;
        }
        if(bnd)return;

        let obj = this.state.load;
        obj.total=0;
        obj.page=1;
        this.execute(obj)
    }

    next = () => { if(this.state.datax.page==this.state.datax.pages)return;
        let obj = this.state.load;
        obj.total=this.state.datax.total;
        obj.page++
        this.execute(obj)
    }

    back = () => { if(this.state.datax.page==1)return;
        let obj = this.state.load;
        obj.total=this.state.datax.total;
        obj.page--
        this.execute(obj)
    }

    execute(obj){
        this.setState({execute:true});
        let thix=this;

        try {
          executeService(thix, obj);
        } catch (error) {
          console.error(error.response);
          let datax={error:true,message:'Error: '+error.message,total:0,header:[],body:[]};
          thix.setState({datax});
          thix.setState({execute:false});
        }
    }

    exportReport = (isCsv) => { if(this.state.executeCsv||this.state.executeExcel) return;
        let thix=this
        let obj = this.state.load;
        obj.show = (isCsv)?1:99;
        
        (isCsv)? this.setState({executeCsv:true}) : this.setState({executeExcel:true});

        try {
          exportService(thix, obj, isCsv);
        } catch (error) {
          (isCsv)? thix.setState({executeCsv:false}) : thix.setState({executeExcel:false});
          console.error(error);
          alertShow('danger', error.message);
        }
    }

    render() {
      const { userRole, userName, userPicture } = this.props;

      return (
        <>
          <Sidebar userRole={userRole} userName={userName} userPicture={userPicture} />
          <div className="main-panel">
            <div className="container">
            <div className="page-inner">
              <div className="row">             

                      <div className="col-md-12">
                          <div className="card">
                              <div className="card-header">
                                  <div className="card-head-row">
                                      <div className="card-title">{this.state.load.name}</div>
                                  </div>
                              </div>
                              <div className="card-body">

                                  <div className="row params">
                                      {this.state.load.params.map(({name,attr,type,value})=>{
                                          return (
                                              <div className="col-md-2" Style="padding:0">
                                                  <div className={"form-group"+(((value==''||value==null)&&this.state.bndInit)?' has-error':'')}>
                                                      <label>{name}</label>
                                                      <input type={(type=='decimal'||type=='integer')?type="number":((type=='list')?'text':type)} className="form-control" value={value} onChange={(e)=>paramsSet(this,attr,e.target.value)} />
                                                  </div>
                                              </div>
                                          )
                                      })}

                                      <div className="col text-right">
                                          <button class="btn btn-primary btn-round" Style="margin-top:30px;padding:8px 40px;margin-bottom:10px" onClick={()=>this.executeReport() }>
                                              {!this.state.execute &&
                                                  <div Style="padding:3px">Ejecutar reporte</div>}
                                              {this.state.execute &&
                                                  <div class="spinner"></div>}
                                          </button>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>

                      {this.state.datax.header.length>0 &&
                      <div className="col-md-12">
                          <div className="card">
                              <div className={"card-header"+((this.state.execute)?' d-none':'')} Style="padding:10px 20px">
                                  <div className="row">
                                      <div className="col-md-4">
                                              <button className="btn btn-primary btn-borderx btn-round btn-sm btn-border" Style={"margin-right:6px;"+((this.state.executeCsv)?'padding-left:35px;padding-right:35px':'')} onClick={()=>this.exportReport(true)}>
                                                  {!this.state.executeCsv &&
                                                      <div Style="padding:3px">Exportar CSV</div>}
                                                  {this.state.executeCsv &&
                                                      <div class="spinner spinner-sm"></div>}
                                              </button>
                                      </div>
                                      <div className="col-md-4 text-center" Style="padding-top:5px">
                                          {this.state.datax.total} <span Style="color:#9a9a9a">Resultados</span> 
                                      </div>
                                      <div className="col-md-4 text-right">
                                              <button class={"btn btn-border btn-round btn-sm"+((this.state.datax.page==1)?' btn-disabled':' btn-primary')} onClick={this.back} Style="border-top-right-radius:0!important;border-bottom-right-radius: 0!important;margin-right:5px">
                                                  <i className="fas fa-angle-left"></i>
                                              </button>
                                              <span><span Style="color:#9a9a9a">Pág.</span> {this.state.datax.page} <span Style="color:#9a9a9a">de</span> {this.state.datax.pages}</span>
                                              <button class={"btn btn-border btn-round btn-sm"+((this.state.datax.page==this.state.datax.pages)?' btn-disabled':' btn-primary')}  onClick={this.next} Style="border-top-left-radius:0!important;border-bottom-left-radius: 0!important;margin-left:5px">
                                                  <i className="fas fa-angle-right"></i>
                                              </button>
                                      </div>
                                  </div>
                              </div>
                              <div className="card-body">
                                  {!this.state.execute &&
                                      <div class="table-responsive">
                                          <table class="table table-bordered table-striped">
                                              <thead>
                                                  <tr>
                                                  {this.state.datax.header.map((str)=>{
                                                      return(<th>{str}</th>)
                                                  })}
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {this.state.datax.body.map((itm)=>{
                                                      return(<tr>
                                                                  {itm.map((str)=>{
                                                                      return(<td>{str}</td>)
                                                                  })}
                                                          </tr>)
                                                  })}
                                              </tbody>
                                          </table>
                                      </div>
                                  }
                                  {this.state.execute && 
                                      <div Style="padding:20px 47%"><div class="spinner spinner-lg"></div></div>
                                  }
                              </div>
                              <div className={"card-footer row"+((this.state.execute)?' d-none':'')}>
                                      <div className="col-md-6" Style="padding-top:5px">
                                              {this.state.datax.body.length} <span Style="color:#9a9a9a"> Mostrados</span> 
                                      </div>
                                      <div className="col-md-6 text-right">
                                      <button class={"btn btn-border btn-round btn-sm"+((this.state.datax.page==1)?' btn-disabled':' btn-primary')} onClick={this.back} Style="border-top-right-radius:0!important;border-bottom-right-radius: 0!important;margin-right:5px">
                                                  <i className="fas fa-angle-left"></i>
                                              </button>
                                              <span><span Style="color:#9a9a9a">Pág.</span> {this.state.datax.page} <span Style="color:#9a9a9a">de</span> {this.state.datax.pages}</span>
                                              <button class={"btn btn-border btn-round btn-sm"+((this.state.datax.page==this.state.datax.pages)?' btn-disabled':' btn-primary')}  onClick={this.next} Style="border-top-left-radius:0!important;border-bottom-left-radius: 0!important;margin-left:5px">
                                                  <i className="fas fa-angle-right"></i>
                                              </button>
                                      </div>
                              </div>
                          </div>
                      </div>}

                      {this.state.datax.error &&
                      <div className="col-md-12">
                          <div class="card">
                              <div class="card-header collapsed" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" role="button">
                                  <div class="span-title text-danger">
                                      Ocurrio un problema al generar el reporte.
                                  </div>
                              </div>

                              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                  <div class="card-body">
                                  {this.state.datax.message}
                                  </div>
                              </div>
                          </div>
                      </div>}

                  </div>
              </div>
            </div>
            <Footer/>
          </div>
        </>
      )
    }
}

export default withAuth0(ReportViewer)