import {
  FETCH_USER_METADATA_REQUEST,
  FETCH_USER_METADATA_SUCCESS,
  FETCH_USER_METADATA_FAILURE
} from './actionTypes';
import { userMetadataService } from './service';

export const userMetadataRequest = () => ({
  type: FETCH_USER_METADATA_REQUEST
});

export const userMetadataSuccess = (data) => ({
  type: FETCH_USER_METADATA_SUCCESS,
  data
});

export const userMetadataFailure = () => ({
  type: FETCH_USER_METADATA_FAILURE
});

export const userMetadata = (id, token) => {
  return async (dispatch) => {
    dispatch(userMetadataRequest());

    try {
      const response = await userMetadataService.get(id, token);

      dispatch(userMetadataSuccess(response));
    } catch (error) {
      dispatch(userMetadataFailure());
    }
  }
}
