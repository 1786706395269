import React from 'react'
import Title from '../components/generic/Title'
import Sidebar from '../components/generic/Sidebar'
import Footer from '../components/generic/Footer'
import Swal from 'sweetalert2';
import Modal from "react-bootstrap/Modal";
import { appAuth0 } from '../config/environments';
import { connectionService } from './state/connection/service';
import { withAuth0 } from '@auth0/auth0-react';

const getListAllReports = async (t) => {
  const accessToken = await t.getToken();
  const response = await connectionService.getAllReports(accessToken);
  t.setState({list:response.data});
}

const getList = async (t, id) => {
  const accessToken = await t.getToken();
  const response = await connectionService.get(accessToken, id);
  t.setState({obj:response.data});
}

const saveConnection = async (t, obj) => {
  const accessToken = await t.getToken();
  await connectionService.post(accessToken, obj);

  getListAllReports(t);
  t.handleClose();
}

const editConnection = async (t, obj) => {
  const accessToken = await t.getToken();
  await connectionService.put(accessToken, obj);

  getListAllReports(t);
  t.handleClose();
}

const deleteConnection = async (t, id) => {
  const accessToken = await t.getToken();
  await connectionService.delete(accessToken, id);

  getListAllReports(t);
}

class CreateCategory extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
          list:[],
          show: false,
          obj:{
              id:0,
              name: "",
              type: "",
              db_host: "",
              db_port: 0,
              db_name: "",
              db_user: "",
              db_pass: ""
          }
      };
      getListAllReports(this);

      this.handleShow = this.handleShow.bind(this)
		  this.handleClose = this.handleClose.bind(this)
  }

  async getToken() {
    const { getAccessTokenSilently } = this.props.auth0;
  
    const accessToken = await getAccessTokenSilently({
      audience: appAuth0.audience,
      scope: 'read:current_user'
    });
    return accessToken;
  };

    handleClose = () => this.setState({ show: false })
    handleShow = (id) => {
        if(id==0){
          this.setState({obj:{
              id:0,
              name: "",
              type: "",
              db_host: "",
              db_port: 0,
              db_name: "",
              db_user: "",
              db_pass: ""
          }})
        }
        else{
          let thix=this;
          getList(thix, id);
        }
        this.setState({ show: true })
    }

    handleSave = () =>{
        let obj = this.state.obj
        let thix=this
        if(obj.id==0)
          saveConnection(thix, obj);
        else
          editConnection(thix, obj);
    }

    handleDel = (id,name) => { 
        let thix=this;
        Swal.fire({
            title: '¿Seguro que desea eliminar?',
            text: 'Se eliminará la conexión: '+name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b7b7b7',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteConnection(thix, id);
                Swal.fire({
                    title: "¡Eliminado!",
                    text: "Se eliminó la conexión",
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }) 
    }
        

    render() {
      const { userRole, userName, userPicture } = this.props;

      return (
        <>
          <Sidebar userRole={userRole} userName={userName} userPicture={userPicture} />
          <div className="main-panel">
            <div className="container">
              <Title
                title="Administar Conexiones" 
                subtitle="Aqui puede crear, editar o eliminar las conexiones a la base de datos"
              />
              <div class="page-inner mt--5">
                  <div class="row">             

                      <div class="offset-md-1 col-md-10">
                          <div class="card">
                              <div class="card-header">
                                  <div class="card-head-row">
                                      <div class="card-title">Lista de conexiones</div>
                                      <div class="card-tools">
                                          <button class="btn btn-primary btn-borderx btn-round btn-sm" onClick={()=>this.handleShow(0)}>Nueva conexión</button>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-body">


                                  <table class="table table-hover">
                                      <thead>
                                          <tr>
                                              <th scope="col"></th>
                                              <th scope="col" className="text-center">Tipo de Base de Datos</th>
                                              <th scope="col">Nombre de la conexión</th>
                                              <th scope="col" className="text-center">Cantidad de reportes</th>
                                              <th scope="col"></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {this.state.list.map(({id,name,type,reports},i)=>{ return(
                                          <tr>
                                              <td>{i+1}</td>
                                              <td className="text-center">{type}</td>
                                              <td>{name}</td>
                                              <td className="text-center">{reports.length}</td>
                                              <td className="text-right">
                                                  <i className="fas fa-edit icon-cat" onClick={()=>this.handleShow(id)}></i>
                                                  <i className="fas fa-trash-alt icon-cat" onClick={()=>this.handleDel(id,name)}></i>
                                              </td>
                                          </tr>
                                      )})}
                                      </tbody>
                                  </table>

                              </div>
                          </div>
                      </div>

                  </div>
              </div>
                <Modal size="lg" show={this.state.show}  onHide={this.handleClose}>
                  <Modal.Header Style="padding:5px 10px;background:#0469ef;color:#fff">
                      <h5 class="modal-title">Conexión</h5>
                      <button type="button" class="close" onClick={this.handleClose}>
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </Modal.Header>
                  <Modal.Body>
                      
                      <div class="row">
                          <div class="col-md-3">
                              <div className="form-group">
                                  <label htmlFor={'typo-bd'}>Tipo</label>
                                  <select id="typo-bd" className="form-control input-border-bottom" value={this.state.obj.type} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.type = e.target.value;
                                                      this.setState({obj}) }} >
                                      <option value="0"></option>
                                      <option value="MySql">MySql</option>
                                      <option value="Postgres">Postgres</option>
                                      {false&&<option value="Oracle">Oracle</option>}
                                  </select>
                              </div>
                          </div>
                          <div class="col-md-9">
                              <div class="form-group">
                                  <label>Nombre de la conexión</label>
                                  <div class="position-relative">
                                      <input type="text" class="form-control input-border-bottom" value={this.state.obj.name} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.name = e.target.value;
                                                      this.setState({obj}) }} />
                                  </div>
                              </div>
                          </div>

                          <div className="col-md-12">
                              <div className="row" Style="background-color:#f8fbfd;border-radius:10px;border:1px solid #e9ecef;margin:10px">
                                  <div class="col-md-9">
                                      <div class="form-group">
                                          <label>Server Host</label>
                                          <div class="position-relative">
                                              <input type="text" class="form-control" value={this.state.obj.db_host} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.db_host = e.target.value;
                                                      this.setState({obj}) }} />
                                          </div>
                                      </div>
                                  </div>

                                  <div class="col-md-3">
                                      <div class="form-group">
                                          <label>Port</label>
                                          <div class="position-relative">
                                              <input type="number" class="form-control" value={this.state.obj.db_port} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.db_port = e.target.value;
                                                      this.setState({obj}) }} />
                                          </div>
                                      </div>
                                  </div>

                                  <div class="col-md-4">
                                      <div class="form-group">
                                          <label>Database</label>
                                          <div class="position-relative">
                                              <input type="text" class="form-control" value={this.state.obj.db_name} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.db_name = e.target.value;
                                                      this.setState({obj}) }} />
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-4">
                                      <div class="form-group">
                                          <label>Usuario</label>
                                          <div class="position-relative">
                                              <input type="text" class="form-control" value={this.state.obj.db_user} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.db_user = e.target.value;
                                                      this.setState({obj}) }} />
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-4">
                                      <div class="form-group">
                                          <label>Contraseña</label>
                                          <div class="position-relative">
                                              <input type="text" class="form-control" value={this.state.obj.db_pass} onChange={(e)=>{
                                                      let obj = {...this.state.obj}
                                                      obj.db_pass = e.target.value;
                                                      this.setState({obj}) }} />
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          
                      </div>

                  </Modal.Body>
                  <Modal.Footer Style="padding:10px">
                          <button type="button" class="btn btn-sm btn-defaultx btn-border" onClick={this.handleClose}>Cancelar</button>
                          <button type="button" class="btn btn-sm btn-primary btn-border ml-2" onClick={this.handleSave}>Guardar</button>
                  </Modal.Footer>
              </Modal>
              </div>
            <Footer/>
          </div>
        </>
      )
    }
}

export default withAuth0(CreateCategory)