const URL_BASE_PATH = 'https://prd.api.mp.sellercenter.pe/v1/report-service'

const CONNECTIONS_GET_ALL = URL_BASE_PATH + '/connections'
const CONNECTIONS_GET_ALL_REPORTS = URL_BASE_PATH + '/connections-reports'
const CONNECTIONS_GET = URL_BASE_PATH + '/connection/'
const CONNECTIONS_POST = URL_BASE_PATH + '/connection'
const CONNECTIONS_PUT = URL_BASE_PATH + '/connection/'
const CONNECTIONS_DEL = URL_BASE_PATH + '/connection/'

const CATEGORIES_GET_ALL = URL_BASE_PATH + '/categories'
const CATEGORIES_GET_ALL_REPORTS_SUM = URL_BASE_PATH + '/categories-reports-sum'
const CATEGORIES_GET_ALL_REPORTS = URL_BASE_PATH + '/categories-reports'
const CATEGORIES_POST = URL_BASE_PATH + '/category'
const CATEGORIES_PUT = URL_BASE_PATH + '/category/'
const CATEGORIES_DEL = URL_BASE_PATH + '/category/'

const REPORT_SAVE_POST = URL_BASE_PATH + '/report'
const REPORT_SAVE_PUT = URL_BASE_PATH + '/report/'
const REPORT_LOAD_GET = URL_BASE_PATH + '/reports-load/'
const REPORT_EXECUTE = URL_BASE_PATH + '/report-execute'
const REPORT_EXPORT = URL_BASE_PATH + '/report-export'
const REPORT_DEL = URL_BASE_PATH + '/report/'
const REPORT_GET = URL_BASE_PATH + '/report/'

export {
    URL_BASE_PATH,

    CONNECTIONS_GET_ALL,
    CONNECTIONS_GET_ALL_REPORTS,
    CONNECTIONS_GET,
    CONNECTIONS_POST,
    CONNECTIONS_PUT,
    CONNECTIONS_DEL,

    CATEGORIES_GET_ALL,
    CATEGORIES_GET_ALL_REPORTS_SUM,
    CATEGORIES_GET_ALL_REPORTS,
    CATEGORIES_POST,
    CATEGORIES_PUT,
    CATEGORIES_DEL,

    REPORT_SAVE_POST,
    REPORT_SAVE_PUT,
    REPORT_LOAD_GET,
    REPORT_EXECUTE,
    REPORT_EXPORT,
    REPORT_GET,
    REPORT_DEL
}