import React from 'react'
import Title from '../components/generic/Title'
import Sidebar from '../components/generic/Sidebar'
import Footer from '../components/generic/Footer'
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';
import { appAuth0 } from '../config/environments';
import { categoryService } from './state/category/service';
import { withAuth0 } from '@auth0/auth0-react';

const getList = async (t) => {
  const accessToken = await t.getToken();
  const response = await categoryService.getAllReportsSum(accessToken);

  t.setState({list:response.data});
}

const saveCategory = async (t, obj) => {
  const accessToken = await t.getToken();
  await categoryService.post(accessToken, obj);
  
  getList(t);
  t.handleClose();
  document.getElementById('sidebar').click();
}

const editCategory = async (t, obj) => {
  const accessToken = await t.getToken();
  await categoryService.put(accessToken, obj);

  getList(t);
  t.handleClose();
  document.getElementById('sidebar').click();
}

const deleteCategory = async (t, id) => {
  const accessToken = await t.getToken();
  await categoryService.delete(accessToken, id);

  getList(t);
  document.getElementById('sidebar').click();
}

class CreateCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list:[],
            show: false,
            id:0,
            txt:""
        };

        getList(this);

        this.handleShow = this.handleShow.bind(this);
		    this.handleClose = this.handleClose.bind(this);
    }
  
    async getToken() {
      const { getAccessTokenSilently } = this.props.auth0;
    
      const accessToken = await getAccessTokenSilently({
        audience: appAuth0.audience,
        scope: 'read:current_user'
      });
      return accessToken;
    };

    handleClose = () => this.setState({ show: false })
    handleShow = (id,txt) => { 
        this.setState({ txt })
        this.setState({ id })
        this.setState({ show: true })
    }

    handleSave = () => { 
        let obj ={
            id:this.state.id,
            name:this.state.txt
        }
        let thix=this
        if(obj.id==0)
          saveCategory(thix, obj);
        else
          editCategory(thix, obj);
    }

    handleDel = (id,name) => { 
        let thix=this;
        Swal.fire({
            title: '¿Seguro que desea eliminar?',
            text: 'Se eliminará la categoría: '+name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b7b7b7',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory(thix, id);
                Swal.fire({
                    title: "¡Eliminado!",
                    text: "Se eliminó la categoría",
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }) 
    }
        
    render() {
      const { userRole, userName, userPicture } = this.props;

      return ( 
        <>
          <Sidebar userRole={userRole} userName={userName} userPicture={userPicture} />
          <div className="main-panel">
            <div className="container">
            <Title 
              title="Administrar Categorías" 
              subtitle="Aqui puede crear, editar o eliminar categorías de reportes"
            />
            <div class="page-inner mt--5">
              <div class="row">             

                  <div class="offset-md-1 col-md-10">
                      <div class="card">
                          <div class="card-header">
                              <div class="card-head-row">
                                  <div class="card-title">Lista de categorías</div>
                                  <div class="card-tools">
                                      <button class="btn btn-primary btn-borderx btn-round btn-sm" onClick={()=>this.handleShow(0,'')}>Nueva categoría</button>
                                  </div>
                              </div>
                          </div>
                          <div class="card-body">

                              

                          <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Nombre de la categoría</th>
              <th scope="col" className="text-center">Cantidad de reportes</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
                              {this.state.list.map(({id,name,reports},i)=>{ return(
            <tr>
              <td>{i+1}</td>
              <td>{name}</td>
              <td className="text-center">{reports}</td>
              <td className="text-right">
                                          <i className="fas fa-edit icon-cat" onClick={()=>this.handleShow(id,name)}></i>
                                          <i className="fas fa-trash-alt icon-cat" onClick={()=>this.handleDel(id,name)}></i>
                                      </td>
            </tr>
                              )})}
          </tbody>
        </table>

                              

                          </div>
                      </div>
                  </div>

              </div>
          </div>
              <Modal show={this.state.show}  onHide={this.handleClose}>
              <Modal.Header Style="padding:5px 10px;background:#0469ef;color:#fff">
                  <h5 class="modal-title" id="exampleModalLabel">Categoría</h5>
                  <button type="button" class="close" onClick={this.handleClose}>
                      <span aria-hidden="true">&times;</span>
                  </button>
              </Modal.Header>
              <Modal.Body>
                  
                  <div class="row">
                      <div class="col-md-12">
                          <div class="form-group">
                              <label for="email2">Nombre de la categoría</label>
                              <div class="position-relative">
                                  <input type="text" class="form-control" value={this.state.txt} onChange={(e)=>this.setState({txt:e.target.value})}/>
                              </div>
                          </div>
                      </div>
                  </div>

              </Modal.Body>
              <Modal.Footer Style="padding:10px">
                      <button type="button" class="btn btn-sm btn-defaultx btn-border" onClick={this.handleClose}>Cancelar</button>
                      <button type="button" class="btn btn-sm btn-primary btn-border ml-2" onClick={this.handleSave}>Guardar</button>
              </Modal.Footer>
          </Modal>
            </div>
            <Footer/>
          </div>
        </>
      )
    }
}

export default withAuth0(CreateCategory)