import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { withAuth0 } from '@auth0/auth0-react';
import { appAuth0 } from './config/environments';
import { routes } from './config/routes';
import { ROLES } from './ui/variables';

import { userMetadata } from './pages/state/userMetadata/actions';
import Header from './components/generic/Header'
//import logo from './logo.svg';
import './App.css';

const history = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: {
        name: '',
        email: '',
        picture: '',
        user_metadata: {
          roles: [{
            name: ''
          }]
        }
      },
      userRol: ''
    };
  }

  async getUserData() {
    const {
      user,
      getAccessTokenSilently
    } = this.props.auth0;

    const accessToken = await getAccessTokenSilently({
      audience: appAuth0.audience,
      scope: 'read:current_user'
    });

    await this.props.store.dispatch(userMetadata(user.sub, accessToken));
    const state = this.props.store.getState().userMetadata.data;

    return state;
  };

  validateRouter(userRole) {
    const { pathname } = window.location;
    const { route } = matchRoutes(routes, pathname)[0];
    let newRoutes = routes;

    if (!route.role.includes(userRole) && userRole.length) {
      newRoutes = routes.filter((item) => item.path !== route.path);
    }

    return renderRoutes(newRoutes, {
      userRole,
      userName: this.state.userData.name,
      userPicture: this.state.userData.picture
    });
  };

  getUserRole(userRoles) {
    let role = userRoles && userRoles.length ? userRoles[0].name : '';
    const rolesName = userRoles ? userRoles.map((item) => (item.name)) : [];

    if (!userRoles || !userRoles.length) {
      window.location.href = "/NotRoles";
    } else if (rolesName.includes(ROLES.ADMIN_REPORTEADOR)) {
      role = ROLES.ADMIN_REPORTEADOR;
    }

    return role;
  };

  async componentDidUpdate(prevProps) {
    if (this.props.auth0.isLoading !== prevProps.auth0.isLoading &&
        this.props.auth0.isAuthenticated !== prevProps.auth0.isAuthenticated &&
        this.props.auth0.isAuthenticated) {
      const userData = await this.getUserData();
      const userRol = this.getUserRole(userData.user_metadata.roles);

      this.setState({ userData });
      this.setState({ userRol })
    }
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <Router history={history}>
          <Header 
            userRole={this.state.userRole} 
            userName={this.state.userData.name} 
            userPicture={this.state.userData.picture}
          />
          <Router>
            {this.validateRouter(this.state.userRol)}
          </Router>
        </Router>
      </Provider>
    );
  }
}

export default withAuth0(App);

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;*/
