import {
  REPORT_SAVE_POST,
  REPORT_SAVE_PUT,
  REPORT_GET,
  REPORT_LOAD_GET,
  REPORT_DEL,
  REPORT_EXECUTE,
  REPORT_EXPORT
} from '../../../utils/urls';
import { httpConfig } from '../../../config/http';

export const reportService = {
  async get(accessToken, id) {
    const response = await httpConfig(accessToken)
      .get(REPORT_GET + id);

      return response;
  },
  async load(accessToken, id) {
    const response = await httpConfig(accessToken)
      .get(REPORT_LOAD_GET + id);

      return response;
  },
  async post(accessToken, data) {
    const response = await httpConfig(accessToken)
      .post(REPORT_SAVE_POST, data);

    return response;
  },
  async put(accessToken, data) {
    const response = await httpConfig(accessToken)
      .put(REPORT_SAVE_PUT + data.id, data)

    return response;
  },
  async delete(accessToken, id) {
    const response = await httpConfig(accessToken)
      .delete(REPORT_DEL + id)

    return response;
  },
  async execute(accessToken, data) {
    const response = await httpConfig(accessToken)
      .post(REPORT_EXECUTE, data);

    return response;
  },
  async export(accessToken, data) {
    const response = await httpConfig(accessToken)
      .post(REPORT_EXPORT, data);

    return response;
  }
}
