import React, { useState } from 'react'

class Title extends React.Component {
    
    render() {
        return (
                    <div className="panel-header bg-primary-gradient">
                        <div className="page-inner py-5">
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                                <div>
                                    <h2 className="text-white pb-2 fw-bold">{this.props.title}</h2>
                                    <h5 className="text-white op-7 mb-2">{this.props.subtitle}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )
    }
}


export default Title