/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
*/


import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react';
import { appAuth0 } from './config/environments';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';

//import 'bootstrap/dist/css/bootstrap.css'

const container = document.getElementById('root');
const history = createBrowserHistory();
const { pathname } = history.location;
const store = configureStore();

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={appAuth0.domain}
    clientId={appAuth0.clientId}
    audience={appAuth0.audience}
    redirectUri={`${window.location.origin}${pathname}`}
    onRedirectCallback={onRedirectCallback}
    scope="read:current_user"
  >
    <App store={store} />
  </Auth0Provider>, 
  container
);
