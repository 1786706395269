import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
  padding-top: 90px;
  padding-left: 5px;
`;

export const NoAvailable = styled.div`
  padding: 150px 50px;
`;
