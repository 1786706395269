export const FONTS = {
  ROBOTO: 'Roboto, sans-serif',
  ROBOTO_MEDIUM: 'Roboto Medium, sans-serif',
};

export const COLORS = {
  GRAY: '#878787',
  BLUE: '#096DD9',
  DARK_GRAY: '#595959',
  LIGHT_GRAY: '#D9D9D9',
  LIGHT_BLUE: '#E6F7FF',
  RED: '#d44950'
};

export const ROLES = {
  CONTENT_MARKETPLACE: 'marketplace_contenido',
  OPERATIONS_MARKETPLACE: 'marketplace_operaciones',
  COMMERCIAL_MARKETPLACE: 'marketplace_comercial',
  COMMERCIAL_SUP_MARKETPLACE: 'marketplace_sup_comercial',
  ADMIN_REPORTEADOR: 'admin_reporteador',
  EXPERIENCE_MARKETPLACE: 'marketplace_experiencia',
  MARKETPLACE_BACK_OFFICE: 'marketplace_back_office',
  MARKETPLACE_VIEW: 'marketplace_view',
  MARKETPLACE: 'marketplace',
  MARKETPLACE_PROMART: 'marketplace_promart',
  MARKETPLACE_PLAZAVEA: 'marketplace_plazavea',
};

export const VIEWS = {
  DASHBOARD: 'dashboard',
  REPORTES: 'reportes',
  REPORTE: 'reporte',
  CATEGORIAS: 'categorias',
  CONEXIONES: 'conexiones',
  VER_REPORTE: 'ver-reporte'
};

export const VIEWS_PERMISSIONS = {
  [VIEWS.DASHBOARD]: [
    ROLES.COMMERCIAL_MARKETPLACE,
    ROLES.CONTENT_MARKETPLACE,
    ROLES.OPERATIONS_MARKETPLACE,
    ROLES.ADMIN_REPORTEADOR,
    ROLES.COMMERCIAL_SUP_MARKETPLACE,
    ROLES.EXPERIENCE_MARKETPLACE,
    ROLES.MARKETPLACE_BACK_OFFICE,
    ROLES.MARKETPLACE_VIEW,
    ROLES.MARKETPLACE,
    ROLES.MARKETPLACE_PLAZAVEA,
    ROLES.MARKETPLACE_PROMART,
  ], 
  [VIEWS.REPORTES]: [
    ROLES.ADMIN_REPORTEADOR
  ], 
  [VIEWS.REPORTE]: [
    ROLES.ADMIN_REPORTEADOR
  ], 
  [VIEWS.CATEGORIAS]: [
    ROLES.ADMIN_REPORTEADOR
  ], 
  [VIEWS.CONEXIONES]: [
    ROLES.ADMIN_REPORTEADOR
  ], 
  [VIEWS.VER_REPORTE]: [
    ROLES.COMMERCIAL_MARKETPLACE,
    ROLES.CONTENT_MARKETPLACE,
    ROLES.OPERATIONS_MARKETPLACE,
    ROLES.COMMERCIAL_SUP_MARKETPLACE,
    ROLES.EXPERIENCE_MARKETPLACE,
    ROLES.ADMIN_REPORTEADOR,
    ROLES.MARKETPLACE_BACK_OFFICE,
    ROLES.MARKETPLACE_VIEW,
    ROLES.MARKETPLACE
  ]
};
