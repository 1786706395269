import React from 'react'
import Title from '../components/generic/Title'
import Sidebar from '../components/generic/Sidebar'
import Footer from '../components/generic/Footer'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2';
import { appAuth0 } from '../config/environments';
import { categoryService } from './state/category/service';
import { reportService } from './state/report/service';
import { withAuth0 } from '@auth0/auth0-react';

const getList = async (t) => {
  const accessToken = await t.getToken();
  const response = await categoryService.getAllReports(accessToken);

  t.setState({list:response.data});
}

const deleteReport = async (t, id) => {
  const accessToken = await t.getToken();
  await reportService.delete(accessToken, id);

  getList(t);
  document.getElementById('sidebar').click();

}

class CreateCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list:[],
            show: false,
        }
        getList(this) 
    }

    async getToken() {
      const { getAccessTokenSilently } = this.props.auth0;
    
      const accessToken = await getAccessTokenSilently({
        audience: appAuth0.audience,
        scope: 'read:current_user'
      });
      return accessToken;
    };
  
    handleDel = (id,name) => { 
        let thix=this;
        Swal.fire({
            title: '¿Seguro que desea eliminar?',
            text: 'Se eliminará el reporte: '+name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b7b7b7',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        }).then((result) => {
            if (result.isConfirmed) {
              deleteReport(thix, id);

              Swal.fire({
                  title: "¡Eliminado!",
                  text: "Se eliminó el reporte",
                  icon: "success",
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 2000
              })
            }
        })
    }

    render() {
      const { userRole, userName, userPicture } = this.props;

      return (
        <>
          <Sidebar userRole={userRole} userName={userName} userPicture={userPicture} />
          <div className="main-panel">
            <div className="container">
              <Title 
                title="Administrar Reportes" 
                subtitle="Aqui puede crear, editar o eliminar reportes"
              />
              <div class="page-inner mt--5">
                <div class="row">             

                    <div class="offset-md-1 col-md-10">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-head-row">
                                    <div class="card-title">Lista de reportes</div>
                                    <div class="card-tools">
                                        <Link class="btn btn-primary btn-borderx btn-round btn-sm" to={'/reporte/0'}>Nuevo reporte</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">

                                

                            <table class="table table-hover">
                                {this.state.list.map(({name,reports},i)=>{ return(<>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" scope="col">{name}</th>
                                            <th scope="col">Conexión</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {reports.map(({id,name,connection},i)=>{ return(
                                        <tr>
                                            <td>{i+1}</td>
                                            <td>{name}</td>
                                            <td>{connection}</td>
                                            <td className="text-right">
                                                <Link to={'/reporte/'+id} className="fas fa-edit icon-cat"></Link>
                                                <i className="fas fa-trash-alt icon-cat" onClick={()=>this.handleDel(id,name)}></i>
                                            </td>
                                        </tr>
                                    )})}  
                                    </tbody>
                                    </>      
                                )})}

          </table>                                        

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </div>
            <Footer/>
          </div>
        </>
      )
    }
}

export default withAuth0(CreateCategory)