import {
  CATEGORIES_GET_ALL,
  CATEGORIES_GET_ALL_REPORTS_SUM,
  CATEGORIES_GET_ALL_REPORTS,
  CATEGORIES_POST,
  CATEGORIES_PUT,
  CATEGORIES_DEL
} from '../../../utils/urls';
import { httpConfig } from '../../../config/http';

export const categoryService = {
  async getAll(accessToken) {
    const response = await httpConfig(accessToken)
      .get(CATEGORIES_GET_ALL);
    
    return response;
  },
  async getAllReportsSum(accessToken) {
    const response = await httpConfig(accessToken)
      .get(CATEGORIES_GET_ALL_REPORTS_SUM);

    return response;
  },
  async getAllReports(accessToken) {
    const response = await httpConfig(accessToken)
      .get(CATEGORIES_GET_ALL_REPORTS);

    return response;
  },
  async post(accessToken, data) {
    const response = await httpConfig(accessToken)
      .post(CATEGORIES_POST, data);

    return response;
  },
  async put(accessToken, data) {
    const response = await httpConfig(accessToken)
      .put(CATEGORIES_PUT + data.id, data)

    return response;
  },
  async delete(accessToken, id) {
    const response = await httpConfig(accessToken)
      .delete(CATEGORIES_DEL + id)

    return response;
  }
}
